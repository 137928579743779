import React, { Component } from 'react';
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import MainScreen from "./app/components/screens/MainScreen";
import PlayScreen from "./app/components/screens/PlayScreen";

// import styles
// import "bulma/bulma.sass";
import "font-awesome/scss/font-awesome.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sass/index.scss";

// auth protected route wrapper
/*
const PrivateRoute = ({ authenticated, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            (!!authenticated)
                ? <Component {...props} />
                : <Redirect to="/login" />
        )}
    />
);
*/

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scalingFactor: Math.min((window.innerHeight / Math.min(window.innerWidth, 1344) / .8), 1),
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    handleResize() {
        this.setState({
            scalingFactor: Math.min((window.innerHeight / Math.min(window.innerWidth, 1344) / .8), 1),
        });
    }

    render() {
        return (
            <div
                className="container"
                style={{
                    transformOrigin: "top center",
                    transform: `scale(${this.state.scalingFactor})`
                }}
            >
                <section className="section">
                    <div className="box">
                        <Router>
                            <Switch>
                                <Route
                                    component={MainScreen}
                                    exact
                                    path="/"
                                />
                                <Route
                                    component={PlayScreen}
                                    exact
                                    path="/play"
                                />
                                <Route
                                    component={
                                        (props) => (<React.Fragment>
                                            <h1>Not Found</h1>
                                            <pre>{JSON.stringify(props, null, 2)}</pre>
                                        </React.Fragment>)
                                    }
                                    exact
                                    path={"*"}
                                />
                            </Switch>
                        </Router>
                    </div>
                </section>
            </div>
        );
    }
}

App.propTypes = {

};

export default connect(state => {
    return {};
}, {})(App);

