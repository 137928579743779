import React from "react";
import PropTypes from "prop-types";
import Card from "../images/Card";

class Player extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let points = [];
        let playerMessage = null;

        if (this.props.player.points === 0) {
            playerMessage = "schwimmt";
        }

        if (this.props.player.points === -1) {
            playerMessage = "ist raus";
        }

        if (!this.props.started) {
            playerMessage = "wartet";
        }

        if (this.props.player.points > 0) {
            for (let i = 0; i < 3; i++) {
                points.push((
                    <div
                        className={`point ${this.props.player.points > i ? "filled" : ""}`}
                        key={i}
                    />
                ));
            }
        }

        let classes = ["_player"];
        if (this.props.current) classes.push("current");
        if (this.props.loosingPlayer) classes.push("loosingPlayer");
        if (this.props.started && !this.props.finished && !this.props.current && this.props.lastCommand) classes.push(this.props.lastCommand);
        if (this.props.started && !this.props.finished && !this.props.hand && this.props.player.points === -1) classes.push("out");
        if (this.props.started && !this.props.finished && this.props.lastCommand === "close") classes.push("closed");

        return (
            <div className={classes.join(" ")}>
                <div className={"headline"}>
                    <span className={"name"}>{this.props.player.name}</span>
                    {
                        this.props.finished &&
                        this.props.player.handPoints &&
                        <span className={"handPoints"}><span className={"value"}>{this.props.player.handPoints}</span> Punkte</span>
                    }
                </div>

                {
                    this.props.started &&
                    points.length > 0 &&
                    <span className={"points"}>{points}</span>
                }

                {
                    playerMessage &&
                    <span className={"playerMessage"}>{playerMessage}</span>
                }

                {
                    /* no hand */
                    !this.props.player.hand &&
                    <div className="cards">
                        <Card />
                        <Card />
                        <Card />
                    </div>
                }

                {
                    /* has hand */
                    this.props.player.hand &&
                    <div className="cards">
                        <Card card={this.props.player.hand[0]} />
                        <Card card={this.props.player.hand[1]} />
                        <Card card={this.props.player.hand[2]} />
                    </div>
                }
            </div>
        );
    }
}

Player.propTypes = {
    current: PropTypes.bool,
    finished: PropTypes.bool,
    lastCommand: PropTypes.string,
    loosingPlayer: PropTypes.bool,
    player: PropTypes.object,
    started: PropTypes.bool,
};

export default Player;
