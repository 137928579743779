import constants from "../constants";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

console.log("constants", "URL_API", constants.URL_API);

export const create = (playerName, gamePassword) => {
    return fetch(`${constants.URL_API}/create`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ playerName, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const join = (gameId, playerName, gamePassword) => {
    return fetch(`${constants.URL_API}/join`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerName, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const start = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/start`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const status = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/status`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const keep = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/keep`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const switchHand = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/switch`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const close = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/close`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const skip = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/skip`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const exchangeOne = (gameId, gamePassword, playerId, playFieldIndex, handIndex) => {
    return fetch(`${constants.URL_API}/actions/exchangeOne`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword, playFieldIndex, handIndex }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const exchangeAll = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/exchangeAll`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const redeal = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/redeal`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const listGames = () => {
    return fetch(`${constants.URL_API}/list`, {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const kick = (gameId, gamePassword, playerId, targetPlayerIndex) => {
    return fetch(`${constants.URL_API}/actions/kick`, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({ gameId, playerId, gamePassword, targetPlayerIndex }),
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};



