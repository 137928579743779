import constants from "./constants";

export const doCreate = (playerName, gamePassword, callback) => {
    return { type: constants.ACTION_DO_CREATE, playerName, gamePassword, callback };
};
export const onCreateSuccess = (result) => { return { type: constants.ACTION_ON_CREATE_SUCCESS, ...result }; };
export const onCreateError = (result) => { return { type: constants.ACTION_ON_CREATE_ERROR, ...result }; };


export const doJoin = (gameId, gamePassword, playerName, callback) => {
    return { type: constants.ACTION_DO_JOIN, playerName, gameId, gamePassword, callback };
};
export const onJoinSuccess = (result) => { return { type: constants.ACTION_ON_JOIN_SUCCESS, ...result }; };
export const onJoinError = (result) => { return { type: constants.ACTION_ON_JOIN_ERROR, ...result }; };


export const doStart = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_START, playerId, gameId, gamePassword, callback };
};
export const onStartSuccess = (result) => { return { type: constants.ACTION_ON_START_SUCCESS, ...result }; };
export const onStartError = (result) => { return { type: constants.ACTION_ON_START_ERROR, ...result }; };


export const doStatus = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_STATUS, playerId, gameId, gamePassword, callback };
};
export const onStatusSuccess = (result) => { return { type: constants.ACTION_ON_STATUS_SUCCESS, ...result }; };
export const onStatusError = (result) => { return { type: constants.ACTION_ON_STATUS_ERROR, ...result }; };


export const doKeep = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_KEEP, playerId, gameId, gamePassword, callback };
};
export const onKeepSuccess = (result) => { return { type: constants.ACTION_ON_KEEP_SUCCESS, ...result }; };
export const onKeepError = (result) => { return { type: constants.ACTION_ON_KEEP_ERROR, ...result }; };


export const doSwitch = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_SWITCH, playerId, gameId, gamePassword, callback };
};
export const onSwitchSuccess = (result) => { return { type: constants.ACTION_ON_SWITCH_SUCCESS, ...result }; };
export const onSwitchError = (result) => { return { type: constants.ACTION_ON_SWITCH_ERROR, ...result }; };


export const doSkip = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_SKIP, playerId, gameId, gamePassword, callback };
};
export const onSkipSuccess = (result) => { return { type: constants.ACTION_ON_SKIP_SUCCESS, ...result }; };
export const onSkipError = (result) => { return { type: constants.ACTION_ON_SKIP_ERROR, ...result }; };


export const doClose = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_CLOSE, playerId, gameId, gamePassword, callback };
};
export const onCloseSuccess = (result) => { return { type: constants.ACTION_ON_CLOSE_SUCCESS, ...result }; };
export const onCloseError = (result) => { return { type: constants.ACTION_ON_CLOSE_ERROR, ...result }; };


export const doExchangeAll = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_EXCHANGE_ALL, playerId, gameId, gamePassword, callback };
};
export const onExchangeAllSuccess = (result) => { return { type: constants.ACTION_ON_EXCHANGE_ALL_SUCCESS, ...result }; };
export const onExchangeAllError = (result) => { return { type: constants.ACTION_ON_EXCHANGE_ALL_ERROR, ...result }; };


export const doExchangeOne = (gameId, gamePassword, playerId, playFieldIndex, handIndex, callback) => {
    return { type: constants.ACTION_DO_EXCHANGE_ONE, playerId, gameId, gamePassword, playFieldIndex, handIndex, callback };
};
export const onExchangeOneSuccess = (result) => { return { type: constants.ACTION_ON_EXCHANGE_ONE_SUCCESS, ...result }; };
export const onExchangeOneError = (result) => { return { type: constants.ACTION_ON_EXCHANGE_ONE_ERROR, ...result }; };


export const doListGames = (callback) => {
    return { type: constants.ACTION_DO_LIST_GAMES, callback };
};
export const onListGamesSuccess = (result) => { return { type: constants.ACTION_ON_LIST_GAMES_SUCCESS, ...result }; };
export const onListGamesError = (result) => { return { type: constants.ACTION_ON_LIST_GAMES_ERROR, ...result }; };


export const doRedeal = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_REDEAL, gameId, gamePassword, playerId, callback };
};
export const onRedealSuccess = (result) => { return { type: constants.ACTION_ON_REDEAL_SUCCESS, ...result }; };
export const onRedealError = (result) => { return { type: constants.ACTION_ON_REDEAL_ERROR, ...result }; };


export const doKick = (gameId, gamePassword, playerId, targetPlayerIndex, callback) => {
    return { type: constants.ACTION_DO_KICK, gameId, gamePassword, playerId, targetPlayerIndex, callback };
};
export const onKickSuccess = (result) => { return { type: constants.ACTION_ON_KICK_SUCCESS, ...result }; };
export const onKickError = (result) => { return { type: constants.ACTION_ON_KICK_ERROR, ...result }; };


export const doClear = (callback) => {
    return { type: constants.ACTION_DO_CLEAR, callback };
};