import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { doStatus, doClear, } from "../actions";

const TIMEOUT = 1000;

class AutoResponder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.handleLoop = this.handleLoop.bind(this);

        this.timeout = null;
    }

    componentDidMount() {
        this.timeout = setTimeout(this.handleLoop, TIMEOUT);

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        if (this.timeout) {
            // clearTimeout(this.timeout);
            // this.timeout = null;
        }

        // this.props.doClear();
    }

    handleLoop() {
        if (!this.props.state.gameId) {
            return;
        }

        this.props.doStatus(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
            if (result.error || error) {
                console.log("Notifier error", result, error);
                return;
            }

            this.timeout = setTimeout(this.handleLoop, TIMEOUT);
        });
    }

    render() {
        return (<div className={"autoresponder"} />);
    }
}

AutoResponder.propTypes = {
    doStatus: PropTypes.func,
    state: PropTypes.object,
};

export default connect((state) => {
    return {
        state: state.state
    };
}, {
    doClear,
    doStatus,
})(AutoResponder);
