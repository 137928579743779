import React from "react";
import PropTypes from "prop-types";

class Game extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className={"_game"}>
                <div className="columns">
                    <div className="column">{this.props.game.player.join(", ")}</div>
                    <div className="column">{this.props.game.numberOfPlayers} / 8</div>
                    <div className="column">
                        <button
                            className={"button is-fullwidth is-large is-primary"}
                            disabled={this.props.disabled}
                            onClick={this.props.onClick}
                        >Spiel beitreten</button>
                    </div>
                </div>
            </div>
        );
    }
}

Game.propTypes = {
    disabled: PropTypes.bool,
    game: PropTypes.object,
    onClick: PropTypes.func,
};

export default Game;
