import React from "react";
import PropTypes from "prop-types";

class Card extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        return (
            <div
                className={"_card " + this.props.className}
                onClick={this.props.onClick}
            >
                {
                    this.props.card &&
                    <img
                        alt={`${this.props.card.value.toLowerCase()}_of_${this.props.card.color.toLowerCase()}`}
                        src={`/assets/images/playing-cards/svg-cards/${this.props.card.value.toLowerCase()}_of_${this.props.card.color.toLowerCase()}.svg`}
                        style={this.props.style}
                    />
                }

                {
                    !this.props.card &&
                    <img
                        alt={"back"}
                        src="/assets/images/playing-cards/svg-cards/cover.svg"
                        style={this.props.style}
                    />
                }
            </div>
        );
    }
}

Card.propTypes = {
    card: PropTypes.object,
    style: PropTypes.object,
};

export default Card;
