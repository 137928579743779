import React from "react";
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { doListGames, doCreate, doJoin } from "../../actions";
import Game from "../list-items/Game";
import PropTypes from "prop-types";

class MainScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            playerName: "",
            gamePassword: "",
        };

        this.handleGamePasswordChange = this.handleGamePasswordChange.bind(this);
        this.handlePlayerNameChange = this.handlePlayerNameChange.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            this.props.doListGames((result, error) => {
                this.setState({ isLoading: false, error });
            });
        });
    }

    handleGamePasswordChange(e) {
        this.setState({ error: null, gamePassword: e.target.value });
    }

    handlePlayerNameChange(e) {
        this.setState({ error: null, playerName: e.target.value });
    }

    handleCreateClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doCreate(this.state.playerName, this.state.gamePassword, (result, error) => {
                if (error || result.status === false) {
                    this.setState({ isLoading: false, error });
                    return;
                }

                this.props.history.push("/play");
            });

        });
    }

    handleJoinClick(gameId) {
        this.setState({ isLoading: true }, () => {
            this.props.doJoin(gameId, this.state.gamePassword, this.state.playerName, (result, error) => {
                if (error || result.status === false) {
                    this.setState({ isLoading: false, error });
                    return;
                }
                this.props.history.push("/play");
            });
        });
    }

    render() {
        let listOfGames;
        if (this.props.listOfGames) {
            listOfGames = this.props.listOfGames.map((game, key) => {
                return (
                    <Game
                        disabled={this.state.isLoading}
                        game={game}
                        key={key}
                        onClick={() => { this.handleJoinClick(game.gameId); }}
                    />
                );
            });
        }

        return (
            <div className={"mainScreen"}>
                <h2 className="title">
                    Schwimmen
                </h2>

                <div className={"field"}>
                    <input
                        className={`input is-large ${this.state.error ? "is-danger" : ""}`}
                        onChange={this.handlePlayerNameChange}
                        placeholder={"Dein Spielername"}
                        type={"text"}
                        value={this.state.playerName}
                    />
                </div>

                <div className={"field"}>
                    <input
                        className={`input is-large ${this.state.error ? "is-danger" : ""}`}
                        onChange={this.handleGamePasswordChange}
                        placeholder={"Passwort für neues Spiel oder zum Beitreten (optional)"}
                        type={"text"}
                        value={this.state.gamePassword}
                    />
                </div>

                {
                    this.state.error &&
                    <React.Fragment>
                        <hr />
                        <div className={"field error"}>
                            {this.state.error.message}
                        </div>
                    </React.Fragment>
                }

                <hr />

                <div className={"columns"}>

                    <div className={"column is-two-fifths"}>
                        <h2 className="title">Neues Spiel erstellen</h2>

                        <div className={"field"}>
                            <button
                                className={"button is-fullwidth is-large is-primary"}
                                disabled={this.state.isLoading}
                                onClick={this.handleCreateClick}
                            >Spiel erstellen</button>
                        </div>
                    </div>

                    <div className={"column is-three-fifths"}>
                        <h2 className="title">Derzeitige Spiele</h2>

                        {
                            this.props.listOfGames.length === 0 &&
                            <div className="_game" style={{ textAlign: "center" }}>Derzeit gibt es keine laufenden Spiele.</div>
                        }

                        {
                            this.props.listOfGames.length > 0 &&
                            listOfGames
                        }
                    </div>
                </div>

                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" style={{ textAlign: "center", marginTop: "100px" }}>
                    <input type="hidden" name="cmd" value="_donations" />
                    <input type="hidden" name="business" value="pawlikmi.hh@gmail.com" />
                    <input type="hidden" name="currency_code" value="EUR" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1" />
                </form>


            </div>
        );
    }
}

MainScreen.propTypes = {
    doCreate: PropTypes.func,
    doJoin: PropTypes.func,
    doListGames: PropTypes.func,
    history: PropTypes.object,
    listOfGames: PropTypes.array,
};

export default connect(state => {
    return {
        listOfGames: state.listOfGames,
    };
}, {
    doListGames,
    doCreate,
    doJoin,
})(withRouter(MainScreen));

