export default {
    URL_API: process.env.REACT_APP_URL_API,

    ACTION_DO_CREATE: "ACTION_DO_CREATE",
    ACTION_ON_CREATE_SUCCESS: "ACTION_ON_CREATE_SUCCESS",
    ACTION_ON_CREATE_ERROR: "ACTION_ON_CREATE_ERROR",
    ACTION_DO_JOIN: "ACTION_DO_JOIN",
    ACTION_ON_JOIN_SUCCESS: "ACTION_ON_JOIN_SUCCESS",
    ACTION_ON_JOIN_ERROR: "ACTION_ON_JOIN_ERROR",
    ACTION_DO_START: "ACTION_DO_START",
    ACTION_ON_START_SUCCESS: "ACTION_ON_START_SUCCESS",
    ACTION_ON_START_ERROR: "ACTION_ON_START_ERROR",
    ACTION_DO_STATUS: "ACTION_DO_STATUS",
    ACTION_ON_STATUS_SUCCESS: "ACTION_ON_STATUS_SUCCESS",
    ACTION_ON_STATUS_ERROR: "ACTION_ON_STATUS_ERROR",
    ACTION_DO_KEEP: "ACTION_DO_KEEP",
    ACTION_ON_KEEP_SUCCESS: "ACTION_ON_KEEP_SUCCESS",
    ACTION_ON_KEEP_ERROR: "ACTION_ON_KEEP_ERROR",
    ACTION_DO_SWITCH: "ACTION_DO_SWITCH",
    ACTION_ON_SWITCH_SUCCESS: "ACTION_ON_SWITCH_SUCCESS",
    ACTION_ON_SWITCH_ERROR: "ACTION_ON_SWITCH_ERROR",
    ACTION_DO_CLOSE: "ACTION_DO_CLOSE",
    ACTION_ON_CLOSE_SUCCESS: "ACTION_ON_CLOSE_SUCCESS",
    ACTION_ON_CLOSE_ERROR: "ACTION_ON_CLOSE_ERROR",
    ACTION_DO_SKIP: "ACTION_DO_SKIP",
    ACTION_ON_SKIP_SUCCESS: "ACTION_ON_SKIP_SUCCESS",
    ACTION_ON_SKIP_ERROR: "ACTION_ON_SKIP_ERROR",
    ACTION_DO_EXCHANGE_ALL: "ACTION_DO_EXCHANGE_ALL",
    ACTION_ON_EXCHANGE_ALL_SUCCESS: "ACTION_ON_EXCHANGE_ALL_SUCCESS",
    ACTION_ON_EXCHANGE_ALL_ERROR: "ACTION_ON_EXCHANGE_ALL_ERROR",
    ACTION_DO_EXCHANGE_ONE: "ACTION_DO_EXCHANGE_ONE",
    ACTION_ON_EXCHANGE_ONE_SUCCESS: "ACTION_ON_EXCHANGE_ONE_SUCCESS",
    ACTION_ON_EXCHANGE_ONE_ERROR: "ACTION_ON_EXCHANGE_ONE_ERROR",
    ACTION_DO_LIST_GAMES: "ACTION_DO_LIST_GAMES",
    ACTION_ON_LIST_GAMES_SUCCESS: "ACTION_ON_LIST_GAMES_SUCCESS",
    ACTION_ON_LIST_GAMES_ERROR: "ACTION_ON_LIST_GAMES_ERROR",
    ACTION_DO_REDEAL: "ACTION_DO_REDEAL",
    ACTION_ON_REDEAL_SUCCESS: "ACTION_ON_REDEAL_SUCCESS",
    ACTION_ON_REDEAL_ERROR: "ACTION_ON_REDEAL_ERROR",
    ACTION_DO_CLEAR: "ACTION_DO_CLEAR",

    ACTION_DO_KICK: "ACTION_DO_KICK",
    ACTION_ON_KICK_SUCCESS: "ACTION_ON_KICK_SUCCESS",
    ACTION_ON_KICK_ERROR: "ACTION_ON_KICK_ERROR",
};
