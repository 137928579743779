import { all, call, put, takeLatest } from 'redux-saga/effects';
import { onCreateSuccess, onCreateError, onJoinSuccess, onJoinError, onStartSuccess, onStartError, onStatusSuccess, onStatusError, onKeepSuccess, onKeepError, onSwitchSuccess, onSwitchError, onCloseSuccess, onCloseError, onSkipSuccess, onSkipError, onExchangeAllSuccess, onExchangeAllError, onExchangeOneSuccess, onExchangeOneError, onListGamesSuccess, onListGamesError, onRedealSuccess, onRedealError, onKickError, onKickSuccess } from "./actions";
import { create, close, keep, start, status, join, switchHand, skip, exchangeAll, exchangeOne, listGames, redeal, kick } from "./services/MockApi";
import constants from "./constants";

function* errorHandler(error, action, errorFunc) {
    yield put(errorFunc(error));

    if (action.callback) {
        action.callback(null, error);
    }
}

export function* watchDoCreate() {
    yield takeLatest(constants.ACTION_DO_CREATE, performCreate);
}
export function* performCreate(action) {
    try {
        const result = yield call(create, action.playerName, action.gamePassword, action.callback);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onCreateSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onCreateError);
    }
}


export function* watchDoJoin() {
    yield takeLatest(constants.ACTION_DO_JOIN, performJoin);
}
export function* performJoin(action) {
    try {
        const result = yield call(join, action.gameId, action.playerName, action.gamePassword);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onJoinSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onJoinError);
    }
}


export function* watchDoStart() {
    yield takeLatest(constants.ACTION_DO_START, performStart);
}
export function* performStart(action) {
    try {
        const result = yield call(start, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onStartSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onStartError);
    }
}


export function* watchDoStatus() {
    yield takeLatest(constants.ACTION_DO_STATUS, performStatus);
}
export function* performStatus(action) {
    let result, error;
    try {
        result = yield call(status, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onStatusSuccess(result));
    } catch (e) {
        error = e;
        yield errorHandler(e, action, onStatusError);
    }

    if (action.callback) {
        action.callback(result, error);
    }
}


export function* watchDoKeep() {
    yield takeLatest(constants.ACTION_DO_KEEP, performKeep);
}
export function* performKeep(action) {
    try {
        const result = yield call(keep, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onKeepSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onKeepError);
    }
}


export function* watchDoSwitch() {
    yield takeLatest(constants.ACTION_DO_SWITCH, performSwitch);
}
export function* performSwitch(action) {
    try {
        const result = yield call(switchHand, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onSwitchSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onSwitchError);
    }
}


export function* watchDoClose() {
    yield takeLatest(constants.ACTION_DO_CLOSE, performClose);
}
export function* performClose(action) {
    try {
        const result = yield call(close, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onCloseSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onCloseError);
    }
}


export function* watchDoSkip() {
    yield takeLatest(constants.ACTION_DO_SKIP, performSkip);
}
export function* performSkip(action) {
    try {
        const result = yield call(skip, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onSkipSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onSkipError);
    }
}


export function* watchDoExchangeAll() {
    yield takeLatest(constants.ACTION_DO_EXCHANGE_ALL, performExchangeAll);
}
export function* performExchangeAll(action) {
    try {
        const result = yield call(exchangeAll, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onExchangeAllSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onExchangeAllError);
    }
}


export function* watchDoExchangeOne() {
    yield takeLatest(constants.ACTION_DO_EXCHANGE_ONE, performExchangeOne);
}
export function* performExchangeOne(action) {
    try {
        const result = yield call(exchangeOne, action.gameId, action.gamePassword, action.playerId, action.playFieldIndex, action.handIndex);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onExchangeOneSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onExchangeOneError);
    }
}


export function* watchDoListGames() {
    yield takeLatest(constants.ACTION_DO_LIST_GAMES, performListGames);
}
export function* performListGames(action) {
    try {
        const result = yield call(listGames);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onListGamesSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onListGamesError);
    }
}


export function* watchDoKick() {
    yield takeLatest(constants.ACTION_DO_KICK, performKick);
}
export function* performKick(action) {
    try {
        const result = yield call(kick, action.gameId, action.gamePassword, action.playerId, action.targetPlayerIndex);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onKickSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onKickError);
    }
}


export function* watchDoRedeal() {
    yield takeLatest(constants.ACTION_DO_REDEAL, performRedeal);
}
export function* performRedeal(action) {
    try {
        const result = yield call(redeal, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onRedealSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onRedealError);
    }
}


export default function* rootSagas() {
    yield all([
        watchDoCreate(),
        watchDoJoin(),
        watchDoStart(),
        watchDoStatus(),
        watchDoKeep(),
        watchDoSwitch(),
        watchDoExchangeAll(),
        watchDoExchangeOne(),
        watchDoSkip(),
        watchDoClose(),
        watchDoListGames(),
        watchDoRedeal(),
        watchDoKick(),
    ]);
}
