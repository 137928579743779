import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { doStart, doKeep, doSwitch, doSkip, doExchangeAll, doExchangeOne, doClose, doRedeal, doKick } from "../../actions";
import Card from "../images/Card";
import Notifier from "../Notifier";
import Player from "../list-items/Player";
import AutoRersponder from "../AutoRersponder";

const deepEqual = require("deep-equal");

class PlayScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            playFieldIndex: null,
            handIndex: null,

            playFieldRotations: [this._getRandomAngle(), this._getRandomAngle(), this._getRandomAngle(),],
            handRotations: [this._getRandomAngle(), this._getRandomAngle(), this._getRandomAngle(),],
            playFieldScales: [1, 1, 1],
        };

        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleKeepClick = this.handleKeepClick.bind(this);
        this.handleSwitchClick = this.handleSwitchClick.bind(this);
        this.handleSkipClick = this.handleSkipClick.bind(this);
        this.handleExchangeAllClick = this.handleExchangeAllClick.bind(this);
        this.handleExchangeOneClick = this.handleExchangeOneClick.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.handleRedealClick = this.handleRedealClick.bind(this);

        this.handleHandSelect = this.handleHandSelect.bind(this);
        this.handlePlayFieldSelect = this.handlePlayFieldSelect.bind(this);

        this.audioRef = React.createRef();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        let change = false;
        let playFieldRotations = this.state.playFieldRotations;
        let handRotations = this.state.handRotations;
        let playFieldScales = [1, 1, 1];

        // field cards changed
        if (!deepEqual(prevProps.state.playField, this.props.state.playField)) {
            change = true;

            if (prevProps.state.playField !== null && this.props.state.playField !== null) {
                for (let key in prevProps.state.playField) {
                    if (
                        prevProps.state.playField[key] &&
                        this.props.state.playField[key] &&
                        prevProps.state.playField[key].id !== this.props.state.playField[key].id) {
                        playFieldRotations[key] = this._getRandomAngle();
                        playFieldScales[key] = 1.1;
                    }
                }
            }
        }

        // hand changed
        if (!deepEqual(prevProps.state.hand, this.props.state.hand)) {
            change = true;

            if (prevProps.state.hand !== null && this.props.state.hand !== null) {
                for (let key in prevProps.state.hand) {
                    if (
                        prevProps.state.hand[key] &&
                        this.props.state.hand[key] &&
                        prevProps.state.hand[key].id !== this.props.state.hand[key].id) {
                        handRotations[key] = this._getRandomAngle();
                    }
                }
            }
        }

        // play sound if u r current player
        if (
            this.props.state.currentPlayer !== null &&
            this.props.state.currentPlayer !== prevProps.state.currentPlayer
        ) {
            document.body.classList.remove("notify");

            if (this.props.state.currentPlayer === this.props.state.myKey) {
                try {
                    document.body.classList.add("notify");

                    if (this.audioRef.current !== null) {
                        this.audioRef.current.play().catch((err) => {
                            console.log("unable to play audio");
                        });
                    }
                } catch (e) {
                    console.log("unable to play audio");
                }
            }
        }


        if (change) {
            this.setState({
                ...this.state,
                playFieldRotations,
                playFieldScales,
                handRotations,
            });

            setTimeout(() => { this.setState({ playFieldScales: [1, 1, 1] }); }, 300);
        }

    }

    _getRandomAngle() {
        let maxAngle = 4.5;
        return (Math.random() * (maxAngle * 2)) - maxAngle;
    }

    handleStartClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doStart(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({
                    playFieldIndex: null, handIndex: null, isLoading: false, error
                });
            });
        });
    }
    handleKeepClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doKeep(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }
    handleSwitchClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doSwitch(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }
    handleSkipClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doSkip(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }
    handleCloseClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doClose(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }
    handleExchangeAllClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doExchangeAll(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }
    handleExchangeOneClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doExchangeOne(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, this.state.playFieldIndex, this.state.handIndex, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }
    handleRedealClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doRedeal(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }

    handleKickClick(playerKey) {
        this.setState({ isLoading: true, playFieldIndex: null, handIndex: null, }, () => {
            this.props.doKick(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, playerKey, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }

    handleHandSelect(index) {
        if (this.props.state.currentPlayer !== this.props.state.myKey) return;
        if (this.state.handIndex === index) index = null;
        this.setState({ handIndex: index });
    }

    handlePlayFieldSelect(index) {
        if (this.props.state.currentPlayer !== this.props.state.myKey) return;
        if (this.state.playFieldIndex === index) index = null;
        this.setState({ playFieldIndex: index });
    }

    render() {
        let players = [];

        for (let playerKey in this.props.state.player) {
            const player = this.props.state.player[playerKey];

            playerKey *= 1;

            players.push(
                <Player
                    current={!this.props.state.finished && this.props.state.currentPlayer === playerKey}
                    finished={this.props.state.finished}
                    key={playerKey}
                    lastCommand={player.lastCommand}
                    loosingPlayer={this.props.state.loosingPlayer && this.props.state.loosingPlayer.indexOf(playerKey) !== -1}
                    player={player}
                    started={this.props.state.started}
                />
            );
        }

        let lastName, lastAction;
        if (this.props.state.lastCommand && this.props.state.player[this.props.state.lastCommand.player]) {
            lastName = this.props.state.player[this.props.state.lastCommand.player].name;
            switch (this.props.state.lastCommand.action) {
                case 'keep': lastAction = "hat die Karten behalten"; break;
                case 'switch': lastAction = "hat abgeworfen"; break;
                case 'skip': lastAction = "hat geschoben"; break;
                case 'close': lastAction = "hat zu gemacht!"; break;
                case 'redeal': lastAction = "hat 7-8-9 getauscht"; break;
                case 'exchangeOne': lastAction = "hat eine Karte getauscht"; break;
                case 'exchangeAll': lastAction = "hat alle Karten getauscht"; break;
                default:
                    lastAction = "";
            }
        }

        let loosingPlayer = null;
        if (this.props.state.loosingPlayer) {
            loosingPlayer = this.props.state.loosingPlayer.map((playerKey) => {
                return <span className={"name"}>{this.props.state.player[playerKey].name}</span>;
            });
        }

        // lastInteraction > MAX_INTERACTION_TIMEOUT
        const MAX_INTERACTION_TIMEOUT = 8;
        let timeoutPlayers = this.props.state.player.map((p, k) => {
            if (p.lastInteraction < MAX_INTERACTION_TIMEOUT) return null;
            return (
                <div key={k} className={"timeoutedPlayer"}>
                    <span className={"name"}>{p.name}</span> scheint nicht mehr da zu sein.
                    <br />
                    <button
                        className={`button is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                        onClick={() => {
                            this.handleKickClick(k);
                        }}>Rausschmeißen</button>
                </div>
            );
        });
        timeoutPlayers = timeoutPlayers.filter(p => p !== null);

        return (
            <React.Fragment>
                <Notifier />

                <audio ref={this.audioRef}>
                    <source
                        src="/assets/sounds/click.mp3"
                        type="audio/mpeg"
                    />
                </audio>

                {
                    !this.props.state.finished &&
                    this.props.state.started &&
                    this.props.state.currentPlayer !== null &&
                    this.props.state.currentPlayer === this.props.state.myKey &&
                    <AutoRersponder />
                }

                {this.state.error && <div className={"field error"}>{this.state.error.message}</div>}

                {
                    /* Runde vorbei */
                    (this.props.state.finished || this.props.state.done) &&
                    <div className="field">
                        {
                            false &&
                            this.props.state.player[this.props.state.closingPlayer] &&
                            <h2 className="title">Runde vorbei!</h2>
                        }
                    </div>
                }

                <div className="columns">
                    <div className="column is-one-fifth left">
                        {players}
                    </div>

                    <div className="column is-three-fiths">


                        {/* playfield */}
                        <div className={"playField"}>
                            {
                                this.props.state.playField &&
                                <div className={"field"}>
                                    <Card
                                        card={this.props.state.playField[0]}
                                        className={this.state.playFieldIndex === 0 ? "selected" : ""}
                                        onClick={() => { this.handlePlayFieldSelect(0); }}
                                        style={{ transform: `rotate(${this.state.playFieldRotations[0]}deg) scale(${this.state.playFieldScales[0]})` }}
                                    />
                                    <Card
                                        card={this.props.state.playField[1]}
                                        className={this.state.playFieldIndex === 1 ? "selected" : ""}
                                        onClick={() => { this.handlePlayFieldSelect(1); }}
                                        style={{ transform: `rotate(${this.state.playFieldRotations[1]}deg) scale(${this.state.playFieldScales[1]})` }}
                                    />
                                    <Card
                                        card={this.props.state.playField[2]}
                                        className={this.state.playFieldIndex === 2 ? "selected" : ""}
                                        onClick={() => { this.handlePlayFieldSelect(2); }}
                                        style={{ transform: `rotate(${this.state.playFieldRotations[2]}deg) scale(${this.state.playFieldScales[2]})` }}
                                    />
                                </div>
                            }
                            {
                                !this.props.state.playField &&
                                <div className={"field"}>
                                    <Card style={{ transform: `rotate(${this.state.playFieldRotations[0]}deg)` }} />
                                    <Card style={{ transform: `rotate(${this.state.playFieldRotations[1]}deg)` }} />
                                    <Card style={{ transform: `rotate(${this.state.playFieldRotations[2]}deg)` }} />
                                </div>
                            }
                        </div>

                        <div className="field middleArea">

                            {
                                /* Letzte Aktion */
                                !this.props.state.finished &&
                                this.props.state.currentPlayer !== null &&
                                this.props.state.currentPlayer !== this.props.state.myKey &&
                                this.props.state.player[this.props.state.currentPlayer] &&
                                this.props.state.lastCommand &&
                                <div className="field lastCommand">
                                    <h2 className="title"><span className={"name"}>{lastName}</span>  {lastAction}</h2>
                                </div>
                            }

                            {
                                /* who's turn is it? */
                                !this.props.state.finished &&
                                this.props.state.currentPlayer !== null &&
                                this.props.state.currentPlayer !== this.props.state.myKey &&
                                this.props.state.player[this.props.state.currentPlayer] &&
                                <h2 className="title"><span className={"name"}>{this.props.state.player[this.props.state.currentPlayer].name}</span> ist dran</h2>
                            }


                            {
                                /* lets wait for start */
                                !this.props.state.finished &&
                                (!this.props.state.started || this.props.state.finished || this.props.state.done) &&
                                this.props.state.myKey !== this.props.state.adminPlayer &&
                                <h2 className="title">Wir warten auf den Spielstart</h2>
                            }


                            {
                                /* round is finished */
                                this.props.state.finished &&
                                <h2 className="title">Runde zu Ende!</h2>
                            }

                            {
                                /* show loosing players */
                                false &&
                                this.props.state.finished &&
                                loosingPlayer &&
                                <div className={"loosingPlayerContainer"}>
                                    <h2 className={"subtitle"}>Verlierer der Runde: {loosingPlayer}</h2>
                                </div>
                            }

                            {
                                /* start button */
                                (
                                    (!this.props.state.started || this.props.state.finished || this.props.state.done) &&
                                    this.props.state.myKey === this.props.state.adminPlayer
                                ) &&
                                <div className={"columns"}>
                                    <div className="column is-one-fifth" />
                                    <div className={"column"}>
                                        <button
                                            className={`button is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                            disabled={
                                                (
                                                    this.props.state.started &&
                                                    !this.props.state.finished &&
                                                    !this.props.state.done
                                                ) ||
                                                this.props.state.myKey !== this.props.state.adminPlayer ||
                                                this.props.state.player.length <= 1
                                            }
                                            onClick={this.handleStartClick}
                                        >Start</button>
                                    </div>
                                    <div className="column is-one-fifth" />
                                </div>
                            }

                            {
                                /* your turn? show EXCHANGE buttons! */
                                !this.props.state.finished &&
                                !this.props.state.done &&
                                this.props.state.currentPlayer !== null &&
                                this.props.state.playField !== null &&
                                this.props.state.currentPlayer === this.props.state.myKey &&
                                <div className={"columns"}>
                                    <div className="column is-one-fifth" />
                                    <div className="column">
                                        <button
                                            className={`button action exchangeOne is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                            disabled={
                                                this.props.state.finished ||
                                                this.props.state.done ||
                                                !this.props.state.started ||
                                                !this.props.state.playField ||
                                                this.props.state.myKey !== this.props.state.currentPlayer ||
                                                this.state.handIndex === null ||
                                                this.state.playFieldIndex === null
                                            }
                                            onClick={this.handleExchangeOneClick}
                                        >Eine tauschen</button>
                                    </div>
                                    <div className="column is-one-fifth" />
                                </div>
                            }

                            {
                                /* your turn? first start? */
                                (
                                    !this.props.state.finished &&
                                    !this.props.state.done &&
                                    this.props.state.started &&
                                    !this.props.state.playField &&
                                    this.props.state.myKey === this.props.state.startingPlayer
                                ) &&
                                <div className={"columns"}>
                                    <div className="column">
                                        <button
                                            className={`button is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                            disabled={
                                                this.props.state.finished ||
                                                this.props.state.done ||
                                                !this.props.state.started ||
                                                this.props.state.playField ||
                                                this.props.state.myKey !== this.props.state.startingPlayer
                                            }
                                            onClick={this.handleKeepClick}
                                        >Behalten</button>
                                    </div>

                                    <div className="column">
                                        <button
                                            className={`button is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                            disabled={
                                                this.props.state.finished ||
                                                this.props.state.done ||
                                                !this.props.state.started ||
                                                this.props.state.playField ||
                                                this.props.state.myKey !== this.props.state.startingPlayer
                                            }
                                            onClick={this.handleSwitchClick}
                                        >Abwerfen</button>
                                    </div>
                                </div>
                            }

                        </div>

                        {/* player hand */}
                        <div className={"hand"}>
                            {
                                this.props.state.hand &&
                                <div className={"field"}>
                                    <Card
                                        card={this.props.state.hand[0]}
                                        className={this.state.handIndex === 0 ? "selected" : ""}
                                        onClick={() => { this.handleHandSelect(0); }}
                                        style={{ transform: `rotate(${this.state.handRotations[0]}deg)` }}
                                    />
                                    <Card
                                        card={this.props.state.hand[1]}
                                        className={this.state.handIndex === 1 ? "selected" : ""}
                                        onClick={() => { this.handleHandSelect(1); }}
                                        style={{ transform: `rotate(${this.state.handRotations[1]}deg)` }}
                                    />
                                    <Card
                                        card={this.props.state.hand[2]}
                                        className={this.state.handIndex === 2 ? "selected" : ""}
                                        onClick={() => { this.handleHandSelect(2); }}
                                        style={{ transform: `rotate(${this.state.handRotations[2]}deg)` }}
                                    />
                                </div>
                            }
                            {
                                !this.props.state.hand &&
                                <div className={"field"}>
                                    <Card style={{ transform: `rotate(${this.state.handRotations[0]}deg)` }} />
                                    <Card style={{ transform: `rotate(${this.state.handRotations[1]}deg)` }} />
                                    <Card style={{ transform: `rotate(${this.state.handRotations[2]}deg)` }} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="column is-one-fifth right">

                        <div className="field">
                            <button
                                className={`button action exchangeAll is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                disabled={
                                    this.props.state.finished ||
                                    this.props.state.done ||
                                    !this.props.state.started ||
                                    !this.props.state.playField ||
                                    this.props.state.myKey !== this.props.state.currentPlayer
                                }
                                onClick={this.handleExchangeAllClick}
                            >Alle tauschen</button>
                        </div>

                        <div className={"field"}>
                            <button
                                className={`button action skip is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                disabled={
                                    this.props.state.finished ||
                                    this.props.state.done ||
                                    !this.props.state.started ||
                                    !this.props.state.playField ||
                                    this.props.state.myKey !== this.props.state.currentPlayer
                                }
                                onClick={this.handleSkipClick}
                            >Schieben</button>
                        </div>

                        <div className={"field"}>
                            <button
                                className={`button action close is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                disabled={
                                    this.props.state.finished ||
                                    this.props.state.done ||
                                    !this.props.state.started ||
                                    !this.props.state.playField ||
                                    !this.props.state.firstRoundDone ||
                                    this.props.state.myKey !== this.props.state.currentPlayer
                                }
                                onClick={this.handleCloseClick}
                            >Zu machen</button>
                        </div>

                        <div className={"field"}>
                            <button
                                className={`button action redeal is-fullwidth is-large is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                                disabled={
                                    this.props.state.finished ||
                                    this.props.state.done ||
                                    !this.props.state.started ||
                                    !this.props.state.playField ||
                                    this.props.state.myKey !== this.props.state.currentPlayer ||
                                    this.props.state.playField.filter(card => card.value === "SEVEN").length !== 1 ||
                                    this.props.state.playField.filter(card => card.value === "EIGHT").length !== 1 ||
                                    this.props.state.playField.filter(card => card.value === "NINE").length !== 1
                                }
                                onClick={this.handleRedealClick}
                            >7-8-9 tauschen</button>
                        </div>
                    </div>
                </div>

                {
                    /*
                    <div className="field">
                        Spiele ID: <p className="subtitle">{this.props.state.gameId}</p>
                    </div>
                    */
                }


                {timeoutPlayers}

            </React.Fragment>
        );
    }
}

PlayScreen.propTypes = {
    doClose: PropTypes.func,
    doExchangeAll: PropTypes.func,
    doExchangeOne: PropTypes.func,
    doKeep: PropTypes.func,
    doKick: PropTypes.func,
    doRedeal: PropTypes.func,
    doSkip: PropTypes.func,
    doStart: PropTypes.func,
    doSwitch: PropTypes.func,
    state: PropTypes.object,
};

export default connect(state => {
    return state;
}, {
    doStart, doKeep, doSwitch, doSkip, doExchangeAll, doExchangeOne, doClose, doRedeal, doKick
})(PlayScreen);

