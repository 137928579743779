import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { doStatus, doClear, } from "../actions";

const TIMEOUT = 1000;

class Notifier extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.handleLoop = this.handleLoop.bind(this);
        this.handleUnmount = this.handleUnmount.bind(this);

        this.timeout = null;

        this.domRef = React.createRef();
    }

    componentDidMount() {
        this.timeout = setTimeout(this.handleLoop, TIMEOUT);
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        this.handleUnmount();
    }

    handleUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        this.props.doClear();
    }

    handleLoop() {
        if (!this.props.state.gameId) {
            return;
        }

        this.setState({ isLoading: true }, () => {
            this.props.doStatus(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                if (result?.error || error) {
                    console.log("Notifier error", result, error);
                }

                this.setState({ isLoading: false }, () => {
                    if (this.domRef.current === null) {
                        return this.handleUnmount();
                    }
                    this.timeout = setTimeout(this.handleLoop, TIMEOUT);
                });
            });
        });
    }

    render() {
        return (
            <button
                ref={this.domRef}
                className={`notifier button is-primary ${this.state.isLoading ? "is-loading" : ""}`}
                disabled
            />
        );
    }
}

Notifier.propTypes = {
    doClear: PropTypes.func,
    doStatus: PropTypes.func,
    state: PropTypes.object,
};

export default connect((state) => {
    return {
        state: state.state,
    };
}, {
    doClear,
    doStatus,
})(Notifier);
