export default {
    state: {
        gameId: null,
        myKey: null,
        myId: null,
        started: true,
        adminPlayer: null,
        currentPlayer: null,
        closingPlayer: null,
        skipPlayer: null,
        closed: false,

        hand: null,
        loosingPlayer: [5],

        handValue: 0,
        player: [
            {
                name: "Player 1",
                points: -1,
                lastCommand: "skip",
                handPoints: 22
            },
            {
                name: "Player 2",
                points: 0,
                lastCommand: "close",
            },
            {
                name: "Player 3",
                points: 1,
                lastCommand: "redeal",
            },
            {
                name: "Player 4",
                points: 2,
                lastCommand: "exchangeOne",
            },
            {
                name: "Player 5",
                points: 3,
                lastCommand: "exchangeAll",
            },
            {
                name: "Player 6",
                points: -1
            }
        ],
        playField: null,
        finished: true,
        done: false,

        firstRoundDone: false,
    },

    listOfGames: [],
};
